import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderSectionsCheckerService {
  private bannerVisibility = new BehaviorSubject<boolean>(true);
  private alertVisibility = new BehaviorSubject<boolean>(false);

  setBannerVisibility(isVisible: boolean): void {
    this.bannerVisibility.next(isVisible);
  }

  getBannerVisibility(): Observable<boolean> {
    return this.bannerVisibility.asObservable();
  }

  setAlertVisibility(isVisible: boolean): void {
    this.alertVisibility.next(isVisible);
  }

  getAlertVisibility(): Observable<boolean> {
    return this.alertVisibility.asObservable();
  }
  getBannerVisibilityValue(): boolean {
    return this.bannerVisibility.value;
  }
  getAlertVisibilityValue(): boolean {
    return this.alertVisibility.value;
  }

}