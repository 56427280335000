import { tap } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BrandService } from '@app/services/brand.service';
import { MultiLanguageName } from '@app/models/common.models';
import { AdminAuthService } from '@services/admin-auth.service';
import { OrganizationService } from '@app/services/organization.service';
import { MobileWidthCheckerService } from '@services/mobile-width-checker.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent implements OnInit {
  brandName: any;
  profileDropdown: MenuItem[] = [];
  currentLang: string;
  selectedCountry: string | undefined;
  isMobileScreen$ = this.mobileWidthCheckerService.isMobileScreen$;
  showDropdown = false;
  selectedDropdownItem: any;
  brand_image_path = '/assets/images/avatar.jpg';
  brandsList: any[] = [];
  isOrganization!: boolean;
  isLoading = false;
  menuItems: any[] = [];
  constructor(
    private router: Router,
    private brandService: BrandService,
    private translate: TranslateService,
    private authService: AdminAuthService,
    private organizationService: OrganizationService,
    private mobileWidthCheckerService: MobileWidthCheckerService
  ) {
    this.currentLang =
      localStorage.getItem('language') || translate.getDefaultLang() || 'en';
  }
  ngOnInit() {
    this.switchLanguage(this.currentLang);
    this.getSelectdBrandInfo();
    const wiserUser = JSON.parse(localStorage.getItem('WiserUser') || '');
    if (wiserUser?.scope === 'organization_owner') {
      this.listBrands();
      this.isOrganization = true;
    } else {
      this.profileDropdown = [
        {
          label: '',
          value: 'info',
          items: [
            ...this.getProfileMenuItems()
          ]
        }
      ];
    }
    this.translate.onLangChange.subscribe((event) => {
      this.switchLanguage(event.lang);
      if (wiserUser?.scope === 'organization_owner') {
        this.listBrands();
        this.isOrganization = true;
      } else {
        this.profileDropdown = [
          {
            label: '',
            value: 'info',
            items: [
              ...this.getProfileMenuItems()
            ]
          }
        ];
      }
    });
  }

  getSelectdBrandInfo() {
    const user = JSON.parse(localStorage.getItem('User') || '');
    this.brandService.brandDetails(user.brand).subscribe((res: any) => {
      if (res) {
        this.brand_image_path = res.image_path || '/assets/images/avatar.jpg';
        this.brandName = res.name;
      }
    });
  }
  listBrands() {
    this.organizationService.getBrandsList().pipe(
      tap((response) => {
        this.brandsList = response.data.brandsWithBranches;
        const brandItems = this.brandsList.map((brand) => ({
          label: brand.name[this.currentLang as keyof MultiLanguageName],
          id: brand._id,
          isBrand: true
        }));
        this.profileDropdown = [
          {
            label: this.translate.instant('brands'),
            value: 'brands',
            items: [
              ...brandItems
            ]
          },
          {
            label: this.translate.instant('info'),
            value: 'info',
            items: [
              ...this.getProfileMenuItems()
            ]
          }
        ];
      })
    ).subscribe();
  }

  getProfileMenuItems() {
    return [
      {
        label: this.translate.instant('profile'),
        value: 'profile',
        id: 'profile',
        command: () => this.navigateToProfile()
      },
      {
        label: this.translate.instant('logOut'),
        value: 'logOut',
        id: 'logOut',
        command: () => this.logOut()
      }
    ];
  }

  switchLanguage(language: string) {
    this.currentLang = language;
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    localStorage.setItem('language', language);
    const html: HTMLHtmlElement = document.getElementsByTagName('html')[0];
    if (html) {
      const langDir = language === 'ar' ? 'rtl' : 'ltr';
      html.classList.remove('rtl', 'ltr');
      html.dir = langDir;
      html.classList.add(langDir);
    }
  }

  onMenuItemSelect(event: any) {
    console.log('event mobile', event)
    if (event.item.label === 'logOut') {
      this.logOut();
    }
  }

  showMenu(event: Event, menu: any): void {
    this.menuItems = this.getProfileMenuItems();
    menu.toggle(event);
  }

  onDropdownChange(event: any) {
    console.log('event', event);
    if (!event?.id) {
      return;
    }
    if (event.id === 'logOut') {
      this.logOut();
    }
    if (event.id === 'profile') {
      this.navigateToProfile()
    }
    if (event.isBrand) {
      this.organizationService.generateTokenForOrganizationOwner({ brandId: event.id }).subscribe((res) => {
        if (res?.statusCode === 200) {
          const business = res.data.business;
          const user = {
            IsLoggedIn: true,
            brand: business.brand,
            id: business._id,
            Email: business.email,
            image_path: business.image_path,
            BoName: `${business.firstname} ${business.lastname}`,
            phone: business.phone
          };
          this.authService.SetInLocalStorage(this.authService.JWT_TOKEN_KEY, business.token);
          this.authService.SetInLocalStorage(this.authService.User_State_KEY, JSON.stringify(user));
          window.location.reload();
        }

      });
    }
  }
  navigateToProfile() {
    this.router.navigate(['profile'])
  }
  logOut() {
    this.authService.LogOut().subscribe(config => {
      window.location.reload();
      return this.router.navigate(['/']);
    });
  }
}