import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class NpsService {

  baseUrl: string = `${environment.API_URL}ratings/partner/`;

  constructor(private http: HttpClient) { }

  checkIfPartnerShouldRate(): Observable<any> {
    return this.http.get(`${this.baseUrl}check-if-should-rate`);
  }

  ignoreRating(): Observable<any> {
    return this.http.post(`${this.baseUrl}ignore`, {}).pipe(
      map(res => res)
    );
  }

  rating(body: any): Observable<any> {
    return this.http.post(`${this.baseUrl}submit`, body).pipe(
      map(res => res)
    );
  }
}