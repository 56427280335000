import { tap } from 'rxjs/operators';
import { config } from '@models/Config';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import User, { UserState } from '@models/user';
import { environment } from '@env/environment';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SentryErrorHandlerService } from '@services/sentry-error-handler.service';

export const ANONYMOUS_USER: UserState = {
  IsLoggedIn: false,
  Email: '',
  scope: ''
};

@Injectable()
export class AdminAuthService {
  private BaseUrl: string = `${environment.API_URL}businesses/`;
  constructor(
    protected http: HttpClient,
    private messageService: MessageService,
    private sentryService: SentryErrorHandlerService
  ) {
    // check if there is keys in localstorage
    this.getFromLocalStorage(this.JWT_TOKEN_KEY);
    const x = this.getFromLocalStorage(this.User_State_KEY);
    const payload = JSON.parse(x);

    if (payload && payload.IsLoggedIn) {
      this.UserAuthState = payload;
      this.sentryService.setUserScope({ email: payload.Email, id: payload.id });
    }
  }
  isAuthenticated() {
    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.UserAuthState);
      }, 100);
    });
    return promise;
  }
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code: ${error.status} (${error.statusText}), ` +
        `Server Messge: "${error.error.message.en}"`
      );
    }
    return of(error);
  }
  SignUp(form: UntypedFormGroup): Observable<any> {
    return this.http
      .post<config<User>>(`${this.BaseUrl}/signup`, form.value, {
        withCredentials: true,
        observe: 'response'
      })
      .pipe(tap(function () { }));
  }

  fakeLogin(accessToken: string, image_path: string, brand: any) {
    interface IJWTPayload {
      id: string;
      email: string;
      scope: string;
    }
    const base64Payload: string = accessToken.split('.')[1];
    const stringifiedPayload: string = this.decodeBase64(base64Payload);
    const jwtPayload: IJWTPayload = JSON.parse(stringifiedPayload);
    const user = {
      IsLoggedIn: true,
      id: jwtPayload.id,
      Email: jwtPayload.email,
      image_path,
      brand,
      scope: jwtPayload.scope
    };
    this.createState(user, accessToken);
  }

  LogIn(form: UntypedFormGroup): Observable<any> {
    return this.http
      .post<config<any>>(`${this.BaseUrl}login`, form.value, {
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        tap((res: any) => {
          const user: any = {
            IsLoggedIn: true,
            brand: res.body.data.business.brand,
            id: res.body.data.business._id,
            Email: res.body.data.business.email,
            image_path: res.body.data.business.image_path,
            BoName: `${res.body.data.business.firstname} ${res.body.data.business.lastname}`,
            phone: res.body.data.business.phone
          };
          this.createState(user, res.body.data.business.token);
        })
      );
  }

  LogOut(): Observable<any> {
    return this.http
      .get<config<any>>(`${this.BaseUrl}logout`, {
        withCredentials: true,
        observe: 'response'
      }).pipe(
        tap(() => {
          localStorage.clear()
        })
      );
  }

  forgetPassword(form: UntypedFormGroup): Observable<any> {
    // return true;
    return this.http
      .post<config<any>>(`${this.BaseUrl}forget_password_email`, form.value, {
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        tap(res => { }));
  }

  readonly JWT_TOKEN_KEY = 'JWT_TOKEN';
  readonly User_State_KEY = 'User';

  readonly JWT_WISER_TOKEN = 'JWT_WISE_TOKEN';
  readonly User_WISER_STATE = 'WiserUser';


  private _UserState = new BehaviorSubject<UserState>(ANONYMOUS_USER);

  UserState$ = this._UserState.asObservable();

  // should be subscribed
  get UserAuthState(): UserState {
    return this._UserState.getValue();
  }

  set UserAuthState(state: UserState) {
    this._UserState.next(state);
  }

  async createState(state: UserState, token: string) {
    const base64Payload: string = token.split('.')[1];
    const stringifiedPayload: string = this.decodeBase64(base64Payload);
    const jwtPayload: any = JSON.parse(stringifiedPayload);
    state.scope = jwtPayload.scope;
    if (jwtPayload && jwtPayload.scope === 'organization_owner') {
      this.SetInLocalStorage(this.JWT_WISER_TOKEN, token);
      this.SetInLocalStorage(this.User_WISER_STATE, JSON.stringify(state));
      const generateToken = await this.generateTokenOrganizationOwner(jwtPayload.brandsIds[0]).toPromise();
      if (generateToken?.body?.statusCode === 200) {
        const business = generateToken.body.data.business;
        const user = {
          IsLoggedIn: true,
          brand: business.brand,
          id: business._id,
          Email: business.email,
          image_path: business.image_path,
          BoName: `${business.firstname} ${business.lastname}`,
          phone: business.phone,
          scope: jwtPayload.scope
        };
        this.SetInLocalStorage(this.JWT_TOKEN_KEY, business.token);
        this.SetInLocalStorage(this.User_State_KEY, JSON.stringify(user));
        this.UserAuthState = user;
      }
    }

    if (jwtPayload && jwtPayload.scope === 'business') {
      this.SetInLocalStorage(this.JWT_TOKEN_KEY, token);
      this.SetInLocalStorage(this.User_State_KEY, JSON.stringify(state));

      this.SetInLocalStorage(this.JWT_WISER_TOKEN, token);
      this.SetInLocalStorage(this.User_WISER_STATE, JSON.stringify(state));
      this.UserAuthState = state;
    }
    window.location.reload();

  }

  generateTokenOrganizationOwner(brandId: string): Observable<any> {
    return this.http
      .post<config<User>>(`${this.BaseUrl}partner/organization-owner-generate-token`, { brandId }, {
        withCredentials: true,
        headers: {
          Authorization: this.getFromLocalStorage(this.JWT_WISER_TOKEN)
        },
        observe: 'response'
      })
      .pipe(tap(function () { }));
  }


  CheckForState() {
    const state = this.getFromLocalStorage(this.User_State_KEY);
    this.UserAuthState = state;
  }

  getFromLocalStorage(key: string): any {
    return localStorage.getItem(key);
  }

  SetInLocalStorage(key: string, item: any) {
    return localStorage.setItem(key, item);
  }

  toastrSuccess(msg: string) {
    this.messageService.add({ severity: 'success', summary: msg });
  }

  toastrError(msg: string) {
    this.messageService.add({ severity: 'error', summary: msg });

  }

  decodeBase64(str: string) {
    return atob(str);
  }
}