import { PaginationDetails, PaginationResponse, ServerPaginationResponse } from '../models/pagination.models';
import { KeyVal } from '../models/utils.models';

export function mapPaginationResponse<T>(response: ServerPaginationResponse<T>): PaginationResponse<T> {
  const { page_no, page_size, no_of_pages, total_items } = response.data.page_details;
  return {
    ...response,
    data: {
      ...response.data,
      pageDetails: {
        pageIndex: page_no ?? 0 - 1,
        pageSize: page_size,
        totalPages: no_of_pages,
        totalItems: total_items
      }
    }
  };
}

export function mapPaginationParams(params: PaginationDetails): KeyVal<string> {
  const { pageIndex, pageSize, totalPages } = params;
  return {
    page_no: `${typeof pageIndex !== 'number' ? 1 : pageIndex}`,
    ...(pageSize ? { page_size: `${pageSize}` } : {}),
    ...(totalPages ? { total_pages: `${totalPages}` } : {})
  };
}