import { MenuItem } from 'primeng/api';
import { sidebarItems } from './sidebar-items';
import { ThemeService } from '../../services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { combineLatest, filter } from 'rxjs';
import { MobileWidthCheckerService } from '../../services/mobile-width-checker.service';
import { AdminAuthService } from '@services/admin-auth.service';
import { BranchService } from '@app/services/Branch.Service';
import { BrandService } from '@app/services/brand.service';
import { HeaderSectionsCheckerService } from '@app/services/header-sections-checker.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  animations: [
    trigger('sidebarAnimation', [
      state('collapsed', style({
        width: '0'
      })),
      state('expanded', style({
        width: '*'
      })),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ]),
    trigger('buttonAnimation', [
      state('collapsed', style({
        marginLeft: '6.7rem'
      })),
      state('expanded', style({
        marginLeft: '15.7rem'
      })),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('tooltipContainer') tooltipContainer!: ElementRef;
  @Output() sidebarStateChange = new EventEmitter<string>();
  checked = true;
  selectedTheme = 'light-mode';
  sidebarClass = 'w-16rem';
  sidebarWidth = '15.7rem';
  sidebarState = 'expanded';
  sidebarImage = 'layer_1.svg';
  items: MenuItem[] = sidebarItems;
  activeIndex: number | null = null;
  selectedSubItem: { itemIndex: number, subItemIndex: number } | null = null;
  tooltipVisible: number | null = null;
  tooltipPositions: { top: number; left: number; right: number }[] = [];
  hideTooltipTimeout: any;
  hoveredIconIndex: number | null = null;
  currentLang!: string;
  isMobileScreen$ = this.mobileWidthCheckerService.isMobileScreen$;
  isSidebarOpenOnMobileScreens = false;
  filterType!: number;
  // auth guard on side menu
  hasLoyaltyRole!: boolean;
  hasAnalyticsRole!: boolean;
  hasFreemiumRole!: boolean;
  brandId: string = '';
  isUtilization: boolean = false;
  sidebarPaddingTopForHeaderSections: number = 24;
  paddingTopForSidebarToggleOnMobile: number = 0
  constructor(
    private router: Router,
    private authService: AdminAuthService,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private themeService: ThemeService,
    private translate: TranslateService,
    private el: ElementRef,
    private mobileWidthCheckerService: MobileWidthCheckerService,
    private service2: BranchService,
    private brandService: BrandService,
    private headerSectionsCheckerService: HeaderSectionsCheckerService
  ) {
    this.currentLang = localStorage.getItem('language') || translate.getDefaultLang() || 'en';
    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
      this.translateSidebarItems();
      this.items = this.applyAuthGuard(sidebarItems);
    });
  }

  async ngOnInit() {
    this.themeService.setTheme(this.themeService.getTheme());
    this.checked = this.themeService.getTheme() === 'light-mode';

    const result: any = await this.brandService.viewSubscriptionPackages().toPromise();
    this.hasLoyaltyRole = result.data.subscriptionPackages.includes('loyalty');
    this.hasAnalyticsRole = result.data.subscriptionPackages.includes('analytics');
    this.hasFreemiumRole = result.data.subscriptionPackages.includes('freemium');
    const user = JSON.parse(localStorage.getItem('User') || '');
    this.service2.getBusinessData(user.id).subscribe(res => {
      const bs = res.body.data;
      this.brandId = bs.brand._id;
      this.brandId = String(bs.brand._id).trim();
      // check if brandId Archi
      this.showUtilizationWithArchi()
    });

    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const currentUrl = event.urlAfterRedirects;
      sidebarItems.forEach((item: any, index: number) => {
        if (item.sub_items) {
          item.sub_items.forEach((subItem: any, subIndex: number) => {
            if (`${subItem.routerLink}${this.filterType}` === currentUrl) {
              this.toggleTab(index);
              this.selectSubItem(index, subIndex);
            }
          });
        }
      });
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.routerState.root;
        let child = currentRoute.firstChild;

        // Traverse down the route tree to find the first child with params
        while (child) {
          if (child.snapshot.params && Object.keys(child.snapshot.params).length) {
            this.filterType = Number(child.snapshot.params['filterType']) || 1;
            break;
          }
          child = child.firstChild;
        }
      }
    });
    this.items = this.applyAuthGuard(sidebarItems);
    this.isBannerAndSubscriptionBranchesExist()
  }

  isBannerAndSubscriptionBranchesExist(): void {
    combineLatest([
      this.headerSectionsCheckerService.getBannerVisibility(),
      this.headerSectionsCheckerService.getAlertVisibility()
    ]).subscribe(([isBannerVisible, isAlertVisible]) => {
      // Padding logic for the sidebar
      if (isBannerVisible && isAlertVisible) {
        this.sidebarPaddingTopForHeaderSections = 100;
        this.paddingTopForSidebarToggleOnMobile = 70;
      } else if (isBannerVisible || isAlertVisible) {
        this.sidebarPaddingTopForHeaderSections = 50;
        this.paddingTopForSidebarToggleOnMobile = isBannerVisible ? 25 : 60;
      } else {
        this.sidebarPaddingTopForHeaderSections = 0;
        this.paddingTopForSidebarToggleOnMobile = 0;
      }
    });
  }


  // check if brandId Archi
  showUtilizationWithArchi() {
    const targetBrandId = '63af0d2edb097543faec3964';
    if (this.brandId === targetBrandId) {
      sidebarItems.push({
        label: 'Utilization',
        img: 'assets/icons/monitor-01.svg',
        icon: 'monitor-01',
        sub_items: [
          { label: 'Utilization', routerLink: '/utilization', allowFilter: false }
        ]
      });

      this.items = this.applyAuthGuard(sidebarItems);
      this.cdr.detectChanges();
    }
  }

  applyAuthGuard(items: any) {
    return items.map((item: any) => {
      // Check and apply conditions on sub_items
      if (item.label === 'loyalty') {
        item.sub_items = item.sub_items.map((subItem: any) => ({
          ...subItem,
          disabled: this.shouldDisableLoyalty(subItem.label)
        }));
      }

      if (item.label === 'reports') {
        item.sub_items = item.sub_items.map((subItem: any) => ({
          ...subItem,
          disabled: this.shouldDisableReports(subItem.label)
        }));
      }

      if (item.label === 'dashboardTitle') {
        item.sub_items = item.sub_items.map((subItem: any) => ({
          ...subItem,
          disabled: !this.hasAnalyticsRole
        }));
      }

      return item;
    });
  }

  shouldDisableLoyalty(label: string): boolean {
    if (['Points rules', 'Points rewards', 'Stamp cards'].includes(label)) {
      return !this.hasLoyaltyRole && !this.hasFreemiumRole;
    }
    if (label === 'Apple wallet') {
      return !this.hasLoyaltyRole;
    }
    return false;
  }

  shouldDisableReports(label: string): boolean {
    if (['Orders', 'Customers'].includes(label)) {
      return !this.hasAnalyticsRole && !this.hasLoyaltyRole && !this.hasFreemiumRole;
    }
    if (['Vouchers', 'Franchise'].includes(label)) {
      return !this.hasAnalyticsRole;
    }
    return false;
  }


  sidebarToggleOnMobiles() {
    this.isSidebarOpenOnMobileScreens = !this.isSidebarOpenOnMobileScreens
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.mobileWidthCheckerService.onResize();
    this.setSidebarConfig(this.expandedConfig);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.isMobileScreen$) {
      const clickedInsideSidebar = this.el.nativeElement.contains(event.target);
      if (!clickedInsideSidebar) {
        this.isSidebarOpenOnMobileScreens = false;
      }
    }
  }

  handleContainerClick(event: MouseEvent) {
    event.stopPropagation();
  }


  async translateSidebarItems() {
    this.items = [];
    this.items = sidebarItems.map((item: any) => {
      const clonedItem = { ...item };
      if (clonedItem['sub_items']) {
        clonedItem['sub_items'] = clonedItem['sub_items'].map((subItem: any) => ({ ...subItem }));
      }
      return clonedItem;
    });

    for (const item of this.items) {
      item.label = await this.translate.get(item.label || '').toPromise();
      if (item['sub_items']) {
        for (const subItem of item['sub_items']) {
          subItem.label = await this.translate.get(subItem.label).toPromise();
        }
      }
    }
    this.cdr.detectChanges();
  }

  // change themes
  toggleTheme(theme: string): void {
    this.checked = theme === 'light-mode';
    this.themeService.setTheme(theme);
  }

  changeThemeFromIcon() {
    this.checked = !this.checked;
    this.toggleTheme(this.checked ? 'light-mode' : 'dark-mode');
  }

  ngAfterViewInit() {
    this.renderer.listen(this.tooltipContainer.nativeElement, 'mouseenter', (event) => {
      event.stopPropagation();
    });
    this.renderer.listen(this.tooltipContainer.nativeElement, 'mouseleave', (event) => {
      this.hideAllTooltips();
    });
  }

  private readonly collapsedConfig = {
    class: 'w-7rem',
    width: '6.7rem',
    image: 'collapsed-layout.svg',
    state: 'collapsed'
  };

  private readonly expandedConfig = {
    class: 'w-16rem',
    width: '15.7rem',
    image: 'layer_1.svg',
    state: 'expanded'
  };

  toggleSidebar() {
    if (this.sidebarWidth === this.expandedConfig.width) {
      this.setSidebarConfig(this.collapsedConfig);
    } else {
      this.setSidebarConfig(this.expandedConfig);
    }

  }

  private setSidebarConfig(config: { class: string, width: string, image: string, state: string }) {
    this.sidebarClass = config.class;
    this.sidebarWidth = config.width;
    this.sidebarImage = config.image;
    this.sidebarState = config.state;
    this.sidebarStateChange.emit(this.sidebarState);
    this.cdr.detectChanges();
  }

  toggleTab(index: number) {
    this.activeIndex = this.activeIndex === index ? null : index;
  }

  selectSubItem(itemIndex: number, subItemIndex: number) {
    this.selectedSubItem = { itemIndex, subItemIndex };
  }

  isSelectedSubItem(itemIndex: number, subItemIndex: number): boolean {
    return this.selectedSubItem?.itemIndex === itemIndex && this.selectedSubItem?.subItemIndex === subItemIndex;
  }

  showTooltip(index: number, event: MouseEvent) {
    clearTimeout(this.hideTooltipTimeout);
    if (this.tooltipVisible !== null && this.tooltipVisible !== index) {
      this.tooltipVisible = null;
    }

    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();
    const tooltipContainerRect = this.tooltipContainer.nativeElement.getBoundingClientRect();

    const sidebarWidth = this.sidebarClass === 'w-16rem' ? 16.2 : 7.2;
    const offset = 67;

    if (this.currentLang === 'ar') {
      this.tooltipPositions[index] = {
        top: rect.bottom - tooltipContainerRect.top - 50,
        left: 0,
        right: tooltipContainerRect.right - rect.right + sidebarWidth + offset
      };
    } else {
      this.tooltipPositions[index] = {
        top: rect.bottom - tooltipContainerRect.top - 50,
        left: rect.left - tooltipContainerRect.left + sidebarWidth + offset,
        right: 0
      };
    }

    this.tooltipVisible = index;
  }



  startHideTooltipTimer() {
    clearTimeout(this.hideTooltipTimeout);
    this.hideTooltipTimeout = setTimeout(() => {
      this.tooltipVisible = null;
    }, 300);
  }

  keepTooltipVisible() {
    clearTimeout(this.hideTooltipTimeout);
  }

  hideAllTooltips() {
    this.tooltipVisible = null;
  }

  isTooltipVisible(index: number): boolean {
    return this.tooltipVisible === index;
  }


  onMouseEnterIcon(index: number): void {
    this.hoveredIconIndex = index;
  }

  onMouseLeaveIcon(): void {
    this.hoveredIconIndex = null;
  }

  isIconHovered(index: number): boolean {
    return this.hoveredIconIndex === index;
  }
  logOut() {
    this.authService.LogOut().subscribe(config => {
      window.location.reload();
      return this.router.navigate(['/']);
    });
  }
}