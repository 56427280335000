import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BrandService } from '@app/services/brand.service';
import { NpsService } from '@app/services/nps.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrl: './nps.component.scss',
  animations: [
    trigger('fade', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible => hidden', animate('1500ms ease-out')),
      transition('hidden => visible', animate('1500ms ease-in'))
    ])
  ],
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, CommonModule]
})
export class NpsComponent implements OnInit {
  @Output() hideNps = new EventEmitter<void>();
  showNpsContainer: boolean = true;
  npsForm!: FormGroup;
  emojis = [
    '/assets/sad-but-relieved.svg',
    '/assets/worried-face.svg',
    '/assets/neutral-face.svg',
    '/assets/smiling-face.svg',
    '/assets/partying-face.svg'
  ];
  selectedRating!: number;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private npsService: NpsService,
    private brandService: BrandService
  ) {}

  ngOnInit(): void {
    this.npsForm = this.fb.group({
      score: ['', [Validators.required, Validators.min(1)]],
      comment: ['', Validators.minLength(2)]
    });
  }

  setRating(index: number) {
    this.selectedRating = (index + 1) * 2;
    this.npsForm.patchValue({ score: this.selectedRating });
    this.submit();
  }

  addClass(event: Event) {
    const buttons = document.querySelectorAll('.w-3rem');
    buttons.forEach((button) => button.classList.remove('active'));
    const clickedButton = event.target as HTMLElement;
    clickedButton.classList.add('active');
  }

  closeNpsContainer() {
    this.showNpsContainer = false;
    setTimeout(() => {
      this.hideNps.emit();
    }, 1000);
  }

  async submit() {
    const data = this.npsForm.value;
    if (!data.comment) {delete data.comment;}
    const response = await this.npsService.rating(data).toPromise();
    if (response?.statusCode === 200) {
      this.brandService.toastrSuccess(response.message.en);
      this.submitted = true;
      setTimeout(() => this.closeNpsContainer(), 2000);
    } else {
      this.brandService.toastrError(response.message.en);
      this.submitted = false;
    }
  }

}