import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdminAuthService } from '@services/admin-auth.service';
import { SpinnerService } from '@app/services/spinner.service';
import { MobileWidthCheckerService } from '@services/mobile-width-checker.service'
import { animate, state, style, transition, trigger } from '@angular/animations';
import { tap } from 'rxjs';
import { BranchesService } from '@app/services/branches.service';
import { NpsService } from '@app/services/nps.service';
import { HeaderSectionsCheckerService } from '@app/services/header-sections-checker.service';



@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  animations: [
    trigger('fadeOut', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', [
        animate('0.5s ease-out')
      ])
    ])
  ]
})
export class LayoutComponent implements OnInit {
  isSidebarOpen = true;
  isAuthenticated = false;
  currentLang: string = 'en';
  isMobileScreen$ = this.mobileWidthCheckerService.isMobileScreen$;
  loading = false;
  isBanner: boolean = true;
  fadeState: string = 'in';
  urlForOldBO: string = '';
  isSubscriptionBranchesAlert: boolean = false;
  subscriptionBranchesDetails: any;
  branchNames: { en: string; ar: string }[] = [];
  subscriptionEndDate: any = [];
  showNps!: boolean;
  constructor(
    public translate: TranslateService,
    private adminService: AdminAuthService,
    private spinnerService: SpinnerService,
    private mobileWidthCheckerService: MobileWidthCheckerService,
    private branchesService: BranchesService,
    private npsService: NpsService,
    private headerCheckerService: HeaderSectionsCheckerService
  ) { }
  ngOnInit() {
    this.currentLang = this.translate.getDefaultLang() || 'en';
    this.translate.onLangChange.subscribe((event) => {
      this.currentLang = event.lang;
    });
    const user = JSON.parse(localStorage.getItem('User') as string);
    if (user) {
      this.isAuthenticated = true;
      const token = localStorage.getItem('JWT_TOKEN');
      const adminId = localStorage.getItem('adminId');
      if (token) {
        this.urlForOldBO = `https://partner.nugttah.com/redirect?log=n&accesstoken=${token}&id=${user.id}&image=${user.image_path}&brand=${user.brand}&adminId=${adminId}`;
      } else {
        this.urlForOldBO = '';
      }
    }
    const isAgent = localStorage.getItem('isAgent');
    this.adminService.isAuthenticated().then((res: any) => {
      if (res.IsLoggedIn && isAgent !== 'true') {
        this.checkShowNps();
      }
    });
    this.spinnerService.spinnerState$.subscribe((state: boolean) => {
      this.loading = state;
    });
    this.adminService.isAuthenticated().then((res: any) => {
      if (res.IsLoggedIn) {
        this.getSubscriptionBranches();
      }
    });
    // this.headerCheckerService.setBannerVisibility(!this.hideBanner);
    // this.headerCheckerService.setAlertVisibility(!this.hideAlert);
  }
  checkShowNps() {
    this.npsService.checkIfPartnerShouldRate().subscribe(
      (response: any) => {
        if (response.data.businessShouldRate) {
          this.showNps = true;
        }
      }
    );
  }

  hideNpsHandler() {
    this.showNps = false;
  }

  onSidebarStateChange(state: string) {
    setTimeout(() => {
      this.isSidebarOpen = (state === 'expanded');
    }, 250);
  }


  onAnimationDone() {
    if (this.fadeState === 'out') {
      this.isBanner = false;
    }
  }

  getSubscriptionBranches() {
    this.branchesService.branchSubscriptionView().subscribe((res: any) => {
      this.subscriptionBranchesDetails = res?.data;
      if (this.subscriptionBranchesDetails?.branches?.length > 0) {
        this.isSubscriptionBranchesAlert = true;
        this.headerCheckerService.setAlertVisibility(true);
        this.branchNames = [];
        this.subscriptionEndDate = [];
        this.subscriptionBranchesDetails.branches.forEach((branch: any) => {
          this.branchNames.push(branch.name);
          this.subscriptionEndDate.push(branch.subscriptionDetails.subscriptionEndDate);
        });
      } else {
        this.isSubscriptionBranchesAlert = false;
        this.headerCheckerService.setAlertVisibility(false);
      }
    });
  }

  getBranchTitle(branch: any): string {
    return this.currentLang === 'en' ? branch.en : branch.ar;
  }

  getBranchName(branch: any): string {
    return this.currentLang === 'en' ? branch.en : branch.ar;
  }

  getAccountManagerName(accountManager: any): string {
    if (!accountManager?.firstname || !accountManager?.lastname) {
      return this.translate.instant('accountManager');
    }
    return `${accountManager.firstname} ${accountManager.lastname}`;
  }

  closeBanner() {
    this.fadeState = 'out';
    const subscriptionBranchesSection = document.querySelector('.warning-alert') as HTMLElement;
    if (subscriptionBranchesSection) {
      if (this.isSubscriptionBranchesAlert) {
        subscriptionBranchesSection['style'].position = 'fixed';
        subscriptionBranchesSection['style'].top = '0';
        subscriptionBranchesSection['style'].left = '0';
        subscriptionBranchesSection['style'].width = '100%';
      } {
        subscriptionBranchesSection['style'].position = 'fixed';
        subscriptionBranchesSection['style'].top = '50';
        subscriptionBranchesSection['style'].left = '0';
        subscriptionBranchesSection['style'].width = '100%';
      }
    }
    this.headerCheckerService.setBannerVisibility(false);
  }

  closeSubBranchesSection() {
    this.isSubscriptionBranchesAlert = false;
    this.headerCheckerService.setAlertVisibility(false);
  }


}