import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { AdminAuthService } from './admin-auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  constructor(
    private http: HttpClient,
    private adminAuthService: AdminAuthService
  ) {
  }
  getBrandsList(): Observable<any> {
    return this.http.get(`${environment.API_URL}brands/partner/list`, {
      withCredentials: true,
      headers: {
        Authorization: this.adminAuthService.getFromLocalStorage(this.adminAuthService.JWT_WISER_TOKEN),
        'Skip-Interceptor': 'true'
      }
    });
  }
  generateTokenForOrganizationOwner(data: any): Observable<any> {
    return this.http.post(`${environment.API_URL}businesses/partner/organization-owner-generate-token`, data, {
      withCredentials: true,
      headers: {
        Authorization: this.adminAuthService.getFromLocalStorage(this.adminAuthService.JWT_WISER_TOKEN),
        'Skip-Interceptor': 'true'
      }
    });
  }
}