// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-container {
  display: grid;
  grid-template-columns: auto 1fr; /* Remaining space for the content */
}
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: none; /* Remove grid-template-columns on mobile */
  }
}

.sidebar {
  width: auto; /* Controlled dynamically via \`ngClass\` */
  min-width: 8rem; /* Prevent collapsing below the smallest sidebar width */
  max-width: 17rem; /* Ensure it does not exceed the largest sidebar width */
}

.content {
  overflow-x: auto; /* Allow horizontal scrolling if content is too wide */
  width: 100%; /* Take the remaining space */
}

p-progressSpinner {
  position: absolute;
  z-index: 9999;
  background-color: #ffffff;
  opacity: 0.5;
  text-align: center;
  padding-top: 200px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.banner-section {
  height: 50px;
  padding: 10px 0px;
  background-color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0%;
  z-index: 999999999999;
  width: 100%;
  box-shadow: 0 8px 10px rgba(24, 23, 23, 0.37);
}
.banner-section .banner-text {
  color: #fff;
  text-align: center;
  flex-grow: 1;
}
.banner-section .banner-link {
  color: #90DEC1;
  font-weight: bold;
}

.warning-alert {
  background-color: var(--color-loss-case);
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  max-height: 50px;
  z-index: 9999;
  line-height: 40px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.warning-alert .subscription-content {
  text-transform: math-auto;
  width: auto;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: math-auto;
  text-align: center;
  margin: 0px 1px;
}
.warning-alert .more-branches {
  text-transform: math-auto;
  margin: 0px 1px;
}
.warning-alert .am-name {
  color: #fff;
  font-weight: 600;
}
.warning-alert .am-name .pi-whatsapp {
  color: rgb(132, 226, 132);
}

@media (max-width: 799px) {
  .warning-alert {
    height: 100px;
    max-height: 100px;
    line-height: 27px;
    display: flex;
    align-items: start;
  }
  .warning-alert .subscription-content {
    max-width: 100px;
  }
  .warning-alert .more-branches {
    display: flex;
    white-space: nowrap;
  }
  .warning-alert .rest-of-content {
    position: absolute;
    left: 0%;
    top: 30px;
    white-space: break-spaces;
    text-align: justify;
    line-height: 22px;
    padding: 0px 20px;
  }
  header.show-alert {
    top: 100px;
  }
}
@media (max-width: 321px) {
  .warning-alert .subscription-content {
    max-width: 70px;
  }
}
.close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 20px;
  margin-left: 10px;
}
@media (max-width: 768px) {
  .close-btn {
    padding: 5px;
  }
}

.fixed-bottom {
  position: fixed;
  width: 100%;
  bottom: 0%;
  left: 0%;
  transform: translate(0%, 0%);
  z-index: 9999999999;
}

.content-padding-top-with-banner {
  padding-top: 25px;
}

.content-padding-top-with-warning-alert {
  padding-top: 25px;
}
@media (max-width: 768px) {
  .content-padding-top-with-warning-alert {
    padding-top: 60px;
  }
}

.content-padding-top-with-both-banners {
  padding-top: 70px;
}

.sidebar-toggle-padding-top-with-one-banner {
  padding-top: 25px;
}

.sidebar-toggle-padding-top-with-both-banners {
  padding-top: 75px;
}

.footer {
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 15px;
  background-color: var(--primary-background-color);
  border-radius: 20px;
  margin-top: 30px;
}
.footer .navigation-section {
  display: flex;
  justify-content: center;
}
.footer .navigation-section ::ng-deep .p-divider.p-divider-vertical {
  padding: 3px 0px;
}
.footer .navigation-section ::ng-deep .p-divider.p-divider-vertical:before {
  padding-left: 1px solid var(--secondary-color);
}
.footer .navigation-section .terms-and-conditions-route,
.footer .navigation-section .about-us-link {
  color: var(--primary-color);
}
.footer .navigation-section .about-us-link {
  text-decoration: none;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
